import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import UserAccountForm from 'containers/users/UserAccountForm';
import { reduxForm, SubmissionError } from 'redux-form';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { getUser, updateUser, usersSelector } from 'reducers/users';
import { connect } from 'react-redux';
import { compose, withState, lifecycle } from 'recompose';
import Exceptions from 'utils/Exceptions';
import { validationErrorToSubmissionError } from 'utils/FormUtils';
import AccountCancelModal from 'containers/users/AccountCancelModal';
import { KeyboardArrowLeft } from '@material-ui/icons';
import Link from 'ui/base/Link';
import { displayAlert } from 'reducers/alerts';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const Form = reduxForm({
    form: 'user',
    validate: UserAccountForm.validate,
    enableReinitialize: true, 
    onSubmit: (values, dispatch, props) => {
        const { getTranslation } = props;
        return dispatch(updateUser(values))
            .then(() => {
                const accountUpdated = getTranslation("account_form_account_updated");
                const emailConfirm = props.initialValues.email !== values.email
                    ? getTranslation("account_form_email_confirmation")+ ' ' + values.email
                    : '';
                
                const message = `${accountUpdated} ${emailConfirm}`.trim();
                dispatch(displayAlert(message));
            })
            .catch((error) => {
                if (error.isException(Exceptions.ValidationException)) {
                    const errors = validationErrorToSubmissionError(error.getData());
                    throw new SubmissionError(errors);
                }
                return Promise.reject(error);
            });
    }
})(UserAccountForm);


function AccountPage({ 
    classes,
    user,
    cancelOpen,
    setCancelOpen
}) {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Link to={`/`}>
                            <Button className={classes.backButton}>
                                <KeyboardArrowLeft /> {getTranslation("home")}
                            </Button>
                        </Link>
                    </div>
                    <Card>
                        <AccountCancelModal open={cancelOpen} onRequestClose={() => setCancelOpen(false)} />
                        <CardContent>
                            <Form 
                                initialValues={user} 
                                paymentForm={false}
                                getTranslation={getTranslation}
                            />
                            <br />
                            <Typography variant="h6" gutterBottom>
                                {getTranslation("user_cancel_account")}
                            </Typography>
                            <Typography color="textSecondary">
                                {getTranslation("user_cancel_account_desc")}
                            </Typography>
                            <br />
                            <Button onClick={() => setCancelOpen(true)}>
                                {getTranslation("user_cancel_my_account")}
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            )}
        </LocaleConsumer>
    );
}

export default compose(
    connect(
        (state) => ({
            user: usersSelector.getCurrentUser(state)
        }),
        (dispatch) => ({
            getUser: (userId) => dispatch(getUser(userId))
        })
    ),
    withStyles({}),
    withState('cancelOpen', 'setCancelOpen', false),
    lifecycle({
        componentDidMount() {
            const { user, getUser } = this.props;
            if (user && user.id) {
                getUser(user.id);
            }
        }
    })
)(AccountPage);
