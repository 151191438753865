import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Card, CardContent, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { withRouter } from 'react-router';
import Link from 'ui/base/Link';
import MentiaLogo from 'images/MentiaLogo2024.jpg';
import {LocaleConsumer} from 'containers/locale/LocaleProvider';

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class JoinSuccessPage extends React.Component {
    state = { };

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        let CAREPLAY_CLIENT_URL;
        let DEVA_CLIENT_URL;

        const baseUrl = new URL(window.location.href).origin;
        
        if (baseUrl.includes('client')) {
            CAREPLAY_CLIENT_URL = process.env.CAREPLAY_DEV_CLIENT_URL;
            DEVA_CLIENT_URL = process.env.DEVA_DEV_CLIENT_URL;
        } else if (baseUrl.includes('au')) {
            CAREPLAY_CLIENT_URL = process.env.CAREPLAY_AU_CLIENT_URL;
            DEVA_CLIENT_URL = process.env.DEVA_AU_CLIENT_URL;
        } else if (baseUrl.includes('fr')) {
            CAREPLAY_CLIENT_URL = process.env.CAREPLAY_FR_CLIENT_URL;
            DEVA_CLIENT_URL = process.env.DEVA_FR_CLIENT_URL;
        } else if (baseUrl.includes('ca')) {
            CAREPLAY_CLIENT_URL = process.env.CAREPLAY_CA_CLIENT_URL;
            DEVA_CLIENT_URL = process.env.DEVA_CA_CLIENT_URL;
        }

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Row className={classes.row} center="xs" middle="xs">
                    <Col xs={12} sm={6} lg={4}>
                        <div className={classes.cardContainer}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent>
                                    <div style={{ textAlign: 'center' }}>
                                        <Typography align="center">
                                            <img src={MentiaLogo} height={50} alt="" />
                                        </Typography>
                                        <br />
                                        <Typography color="primary">
                                            <CheckCircle style={{ verticalAlign: 'middle' }} />
                                        </Typography>
                                        <br />
                                        <Typography>
                                            {` An email / text message has been sent to you to confirm your registration `}
                                        </Typography>
                                        <br />
                                        <Typography>
                                            {getTranslation("signup_greetings_dashboards")}{' '} 
                                            {<Link styled to={CAREPLAY_CLIENT_URL}>
                                                CarePlay
                                            </Link>}
                                            {' '}{getTranslation("signup_greetings_dashboards_2")}{' '}
                                            {getTranslation("signup_greetings_dashboards_3")}{' '}
                                            {<Link styled to={DEVA_CLIENT_URL}>
                                                Mentia Companion
                                            </Link>}
                                            {' '}{getTranslation("signup_greetings_dashboards_2")}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Col>
                </Row>
                )}
            </LocaleConsumer>
        );
    }

    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(withRouter((JoinSuccessPage)));
