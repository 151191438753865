import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { alertsSelector } from 'reducers/alerts';
import { Snackbar } from '@material-ui/core';
import { compose, withState, lifecycle } from 'recompose';


function Alerts({ open, setOpen, alert}) {
    return (
        <Snackbar
            open={open}
            message={alert && alert.message}
            autoHideDuration={50000}
            onClose={() => setOpen(false)}
        />
    );
}

export default compose(
    connect((state) => ({
        currentAlert: alertsSelector.getCurrent(state),
    })),
    withState('open', 'setOpen', false),
    withState('alert', 'setAlert', null),
    lifecycle({
        componentDidUpdate() {
            if (this.props.currentAlert) {
                if (!this.props.alert || this.props.alert.date !== this.props.currentAlert.date) {
                    this.props.setAlert(this.props.currentAlert);
                    this.props.setOpen(true);
                }
            }
        }
    })
)(Alerts);
