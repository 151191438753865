import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Button, Typography } from '@material-ui/core';
import { withState } from 'recompose';
import validator from 'validator';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'
import PasswordField from "ui/base/PasswordField";

const styles = {
    form: {
        '& > *': {
            marginBottom: 20,
        },
    },
};


function UserAccountForm({ 
    classes, 
    handleSubmit, 
    submitting, 
    password, 
    setPassword 
}) {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={classes.form}>
                    <Field
                        type="text"
                        name="email"
                        label={getTranslation("email")}
                        component={ValidatedTextField}
                        fullWidth
                        // Workaround because disabled fields are not submitted
                        inputProps={{ 
                            readOnly: true,
                            style: { color: "#9e9e9e" }
                        }}
                        style={{
                            pointerEvents: "none",
                            userSelect: "none"
                        }}
                    />
                    <Field
                        type="text"
                        name="first_name"
                        label={getTranslation("first_name")}
                        component={ValidatedTextField}
                        required
                        fullWidth
                    />
                    <Field
                        type="text"
                        name="last_name"
                        label={getTranslation("last_name")}
                        component={ValidatedTextField}
                        required
                        fullWidth
                    />
                    {!password && (
                        <Button color="secondary" onClick={() => setPassword(true)}>
                            {getTranslation("user_change_password")}
                        </Button>
                    )}
                    {password && (
                        <div>
                            <Typography variant="h6">{getTranslation("user_change_password")}</Typography>
                            <br />
                            <Field
                                name="password_current"
                                label={getTranslation("user_current_password")}
                                component={PasswordField}
                                fullWidth
                            />
                            <br />
                            <br />
                            <Field
                                name="password"
                                label={getTranslation("password")}
                                component={PasswordField}
                                fullWidth
                            />
                            <br />
                            <br />
                            <Field
                                name="password_confirm"
                                label={getTranslation("account_form_password_confirm")}
                                component={PasswordField}
                                fullWidth
                            />
                        </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                            disabled={submitting}
                        >
                            {getTranslation("update")}
                        </Button>
                    </div>
                </div>
            )}
        </LocaleConsumer>
    );
}

UserAccountForm = withState('password', 'setPassword', false)(UserAccountForm);
UserAccountForm = withStyles(styles)(UserAccountForm);

UserAccountForm.validate = (values) => {
    const errors = {};

    if (!values.first_name) {
        errors.first_name = 'Required';
    }

    if (!values.last_name) {
        errors.last_name = 'Required';
    }

    if (values.password && !validator.isLength(values.password, { min: 6 })) {
        errors.password = 'Password must be at least 6 characters long';
    }

    if (values.password && !values.password_confirm) {
        errors.password_confirm = 'Required';
    } else if (values.password && values.password !== values.password_confirm) {
        errors.password_confirm = 'Your password and confirmation password do not match';
    }

    return errors;
};


export default UserAccountForm;
