import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import AppStore from 'images/AppStore.png';
import GooglePlayStore from 'images/GooglePlayStore.png';
import CarePlayQRAndroid from 'images/CarePlayQRAndroid.png';
import CarePlayQRApple from 'images/CarePlayQRApple.png';
import MentiaLogoShort from 'images/MentiaLogoShort2024.png';
import MentiaCompanionLogo from 'images/MentiaCompanion2024.png';
import DevaWorldLogo from 'images/logo_deva_world.png';
import CarePlayLogo from 'images/CarePlayLogo2024.png';
import CarePlayAppIcon from 'images/CarePlayAppIcon2024.png';
import {LocaleConsumer} from 'containers/locale/LocaleProvider';

function JoinSuccessPage ({ classes }) {
    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <div className={classes.cardContainer} style={{  display: 'flex', justifyContent: 'center' }}>
                    <Card classes={{ root: classes.cardRoot }} style={{ marginTop: '5%', maxWidth: '55%' }}>
                        <CardContent>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '3vh' }}>
                                <Typography align="center" style={{ marginTop: '1rem' }}>
                                    <img src={MentiaLogoShort} height={50} alt="" />
                                </Typography>
                                <Typography style={{ marginTop: '2vh', fontSize: '2.4rem' }}>
                                    {getTranslation("signup_success")}
                                </Typography>
                                <Typography style={{ fontSize: '1.2rem', lineHeight: '1.8',  }}>
                                    {getTranslation("signup_success_registered")}
                                    <br />
                                    <br />
                                    {getTranslation("signup_success_careplay_1")}
                                    <img src={CarePlayLogo} height={26} alt="" style={{ position: 'relative', 'top': '6px' }} />
                                    {getTranslation("signup_success_careplay_2")}
                                    <img src={CarePlayAppIcon} height={50} alt="" />
                                    {getTranslation("signup_success_careplay_3")}
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '15rem', marginBottom: '2rem' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}> 
                                            <img src={CarePlayQRApple} height={170} alt="" />
                                            <a href="https://apps.apple.com/ca/app/careplay/id1560000000" target="_blank" rel="noopener noreferrer">
                                                <img src={AppStore} height={50} alt="" />
                                            </a>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <img src={CarePlayQRAndroid} height={170} alt="" />
                                            <a href="https://play.google.com/store/apps/details?id=me.mentia.careplay" target="_blank" rel="noopener noreferrer">
                                                <img src={GooglePlayStore} height={50} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    {getTranslation("signup_success_mc_dw_1")}
                                    <img src={MentiaCompanionLogo} height={23} alt="" style={{ position: 'relative', top: '4px' }} />
                                    {getTranslation("signup_success_or")}
                                    <img src={DevaWorldLogo} height={23} alt="" style={{ position: 'relative', top: '1px' }} />
                                    {getTranslation("signup_success_mc_dw_2")}
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            )}
        </LocaleConsumer>
    );
}

export default withStyles({})(withRouter((JoinSuccessPage)));
